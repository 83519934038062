<template>
  <div class="privacyPage" @keydown="returnFn($event)">
    <div
      class="back tabindex"
      tabindex="1"
      @keydown="keyDown($event, '/')"
      @click="goPath('/setting')"
    >
      Exit
    </div>
    <div class="textContent">
      <h1>Service and Usage Agreement</h1>
      <p>
        Before using our services, please carefully read the following Terms of Service
        and Usage Agreement.
      </p>
      <h2>1. Service Description</h2>
      <p>Our App provides the following music services:</p>
      <ul>
        <li>Streaming music playback</li>
        <li>Personalized recommendations</li>
        <li>User playlist management</li>
      </ul>
      <h2>2. Terms of Use</h2>
      <h3>2.1 Account and Registration</h3>
      <p>
        Before using our App, you may need to create an account. During the
        registration process, provide accurate, complete information, and ensure
        to update your account information to keep it accurate.
      </p>
      <h3>2.2 Privacy Protection</h3>
      <p>
        We respect user privacy. Learn more about our
        Privacy to understand how we
        collect, use, and protect your personal information.
      </p>
      <h3>2.3 Music Usage Guidelines</h3>
      <p>
        You agree not to use our App in any illegal or unauthorized way, and you
        must comply with applicable music copyright laws and regulations. You
        should only use the music provided in the App in compliance with legally
        stipulated conditions.
      </p>
      <h3>2.4 User-Generated Content</h3>
      <p>
        You are fully responsible for the user-generated content created or
        shared in the App. Ensure that your content does not violate any legal
        regulations and does not infringe upon any third-party rights.
      </p>
      <h3>2.5 Account Security</h3>
      <p>
        You are responsible for protecting your account information and
        password. If you notice any unauthorized use of your account, please
        notify us immediately.
      </p>
      <h3>2.6 Fees and Payments</h3>
      <p>
        Certain features or services may require payment. We will provide clear
        fee information before using these features or services. You agree to
        pay the relevant fees, and understand that we may change the fee
        structure as needed.
      </p>
      <h3>2.7 Limitation of Liability</h3>
      <p>
        We are not liable for any loss or damage resulting from the use or
        inability to use our App, including but not limited to direct, indirect,
        special, incidental, or consequential damages.
      </p>
      <h3>2.8 Service Changes and Termination</h3>
      <p>
        We reserve the right to modify, interrupt, or terminate our services at
        any time, either in whole or in part, without prior notice. We will not
        be liable to users or any third party for any responsibilities arising
        from modifications, interruptions, or terminations of services.
      </p>
      <h2>3. Additional Terms</h2>
      <h3>3.1 Changes to Terms</h3>
      <p>
        We reserve the right to modify or change these Terms of Service and
        Usage Agreement at any time. We will notify you through in-app
        notifications or other appropriate means. Continuing to use our App will
        be considered acceptance of these changes.
      </p>
      <h3>3.2 Contact Us</h3>
      <p>
        If you have any questions about these Terms of Service and Usage
        Agreement, please contact us.
      </p>
      <p>Thank you for using our App!</p>
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goPath (path) {
      this.$router.push(path)
    }
  }
}
</script>

<style lang="scss" scoped>
.privacyPage {
  height: 100%;
  position: relative;
  .back {
    display: block;
    width: 80px;
    font-size: 30px;
    text-align: center;
    color: #fff;
    border-radius: 20px;
    &:focus {
      background: gray;
    }
  }
  .textContent {
    height: 720px;
    overflow-y: scroll;
    color: #fff;
    h1 {
      text-align: center;
      font-size: 45px;
    }
    h2 {
      font-size: 35px;
    }
    h3 {
      font-size: 25px;
      text-indent: 20px;
    }
    p,
    ol,
    li {
      font-size: 25px;
      margin: 20px 0px;
      text-indent: 40px;
    }
  }
}
</style>
